<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="patientPaymentForm" @submit.prevent="savePatientPayment" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Patient Payment Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">P. Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="payment.payment_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="payment.payment_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">P. Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        :items="['Cash', 'Bank']"
                                                        v-model="payment.type"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="payment.type == 'Bank'" no-gutters class="mb-1">
                                                <v-col cols="3">Bank Acc.</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                        :loading="$store.getters['bankAccount/loading']"
                                                        :items="$store.getters['bankAccount/bankAccounts']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="selectedBankAccount"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Patient</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        @focus="$store.dispatch('patient/getPatients')"
                                                        :loading="$store.getters['patient/loading']"
                                                        :items="$store.getters['patient/patients']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="selectedPatient"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Due</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['patient/patientPreviousDue']"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.number="payment.amount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                <v-col cols="9">
                                                    <v-textarea
                                                        dense
                                                        outlined
                                                        hide-details
                                                        height="8.3vh"
                                                        v-model="payment.description"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="patientPaymentHeaders"
                    :loading="$store.getters['patientPayment/loading']"
                    :items="$store.getters['patientPayment/patientPayments']"
                    :search="searchTransaction"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Transaction List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Transaction"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchTransaction"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editPatientPayment(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteTest"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'

export default {
    name: 'PatientPayment',
    components: {
        DeleteConfirm,
    },
    data: () => ({
        menu: false,
        searchTransaction: '',
        patientPaymentHeaders: [
            { text: 'Tr ID', value: 'code' },
            { text: 'Date', value: 'payment_date' },
            { text: 'Patient', value: 'patient.name' },
            { text: 'Payment By', value: 'type' },
            { text: 'Amount', value: 'amount' },
            { text: 'Description', value: 'description' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],

        selectedBankAccount: null,
        selectedPatient: null,

        payment: {
            payment_date: new Date().toISOString().slice(0, 10),
            type: '',
            amount: 0,
            description: '',
        },

        patientPaymentEditId: null,
        patientPaymentDeleteId: null,
        waitingForSave: false,
        userType: ''
    }),
    watch: {
        selectedPatient(val) {
            if (val == null) return
            this.$store.dispatch('patient/getPatientPreviousDue', {patient_id: val.id})
        }
    },
    created() {
        this.$store.dispatch('patientPayment/getPatientPayments')
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type   
    },
    methods: {
        async savePatientPayment() {
            if (!this.$refs.patientPaymentForm.validate()) return
            this.waitingForSave = true

            let newPatientPayment = this.payment
            newPatientPayment.patient_id = this.selectedPatient.id

            if (this.payment.type == 'Bank') {
                newPatientPayment.bank_account_id = this.selectedBankAccount.id
            }

            if (this.patientPaymentEditId == null) {
                let res = await this.$store.dispatch('patientPayment/savePatientPayment', {
                    url: 'add-patient-payment',
                    data: newPatientPayment
                })
                if (res) {
                    this.resetForm()
                    swal({
                        title: "Do you want to show invoice?",
                        icon: "success",
                        buttons: true
                    }).then(response => {
                        if (response) {
                        if (response) this.$router.push(`/account/payment-invoice/${res.transId}`);
                        }
                    });
                }
            } else {
                let res = await this.$store.dispatch('patientPayment/savePatientPayment', {
                    url: 'update-patient-payment',
                    data: {...newPatientPayment, id: this.patientPaymentEditId}
                })
                if (res) {
                    this.resetForm()
                    swal({
                        title: "Do you want to show invoice?",
                        icon: "success",
                        buttons: true
                    }).then(response => {
                        if (response) {
                        if (response) this.$router.push(`/account/payment-invoice/${res.transId}`);
                        }
                    });
                }
            }

            this.waitingForSave = false
        },

        editPatientPayment(item) {
            this.patientPaymentEditId = item.id
            this.selectedPatient = item.patient
            this.selectedBankAccount = item.bank_account
            Object.keys(this.payment).map(k => this.payment[k] = item[k])
        },

        openDeleteDialog(id) {
            this.patientPaymentDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteTest() {
            this.$store.dispatch('patientPayment/deletePatientPayment', {id: this.patientPaymentDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.patientPaymentDeleteId = null
        },
        
        resetForm() {
            this.payment.amount = 0
            this.payment.type = this.payment.description = ''
            this.payment.payment_date = new Date().toISOString().slice(0, 10)
            this.patientPaymentEditId = this.selectedBankAccount = this.selectedPatient = null
            this.$store.dispatch('patient/getPatientPreviousDue')
            this.$refs.patientPaymentForm.resetValidation()
        }
    },
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>